import type { FeatureItemData } from '~/types';
export const features: FeatureItemData[] = [
	{
		id: 'ZenDemoreel',
		h1: '',
		h2: 'Platform',
		copy: 'Build <span>real-time interactive visual experiences</span> with the Zenapptic platform',
		backgrounds: {
			small: '/assets/video/video-landscape@x2.jpg',
			large: '/assets/video/video-landscape@x2.jpg',
		},
		video: {
			url: '/assets/video/zen-demo-reel-720.webm',
			poster: '/assets/video/zen-demo-reel-720-poster.webp',
		},

	},
	// {
	// 	id: 'WorldAreExperience',
	// 	h1: 'World-Scale AR Experiences',
	// 	h2: 'Platform',
	// 	copy: 'Build <span>real-time interactive visual experiences</span> with the Zenapptic platform',
	// 	backgrounds: {
	// 		small: '/assets/features/concrete-jungle-sm.jpg',
	// 		large: '/assets/features/concrete-jungle.jpg',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	id: 'Lobbyverse',
	// 	h1: 'The Lobbyverse: Extend Physical Spaces With Virtual Worlds',
	// 	h2: 'Platform',
	// 	copy: 'Build <span>real-time interactive visual experiences</span> with the Zenapptic platform',
	// 	backgrounds: {
	// 		small: '/assets/features/lobbyverse-sm.jpg',
	// 		large: '/assets/features/lobbyverse.jpg',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	id: 'SportsExperiences',
	// 	h1: 'Sports Experiences',
	// 	h2: 'Platform',
	// 	copy: 'Build <span>real-time interactive visual experiences</span> with the Zenapptic platform',
	// 	backgrounds: {
	// 		small: '/assets/features/sports-experiences-sm.jpg',
	// 		large: '/assets/features/sports-experiences.jpg',
	// 	},
	// 	duration: 10,
	// },
	// },
	// {
	// 	h1: 'Virtual Experience',
	// 	h2: 'Platform',
	// 	copy: 'Build <span>real-time interactive visual experiences</span> with the Zenapptic platform',
	// 	backgrounds: {
	// 		small: '/assets/backgrounds/IntroSlider-sm.jpg',
	// 		large: '/assets/backgrounds/IntroSlider.jpg',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	h1: 'Smart',
	// 	h2: 'Command Centers',
	// 	copy: 'Securely access <span>control room information and real-time data</span> from anywhere for faster, better-informed decision-making.',
	// 	backgrounds: {
	// 		small: '/assets/backgrounds/IntelligentCommandCenters_sm.jpg',
	// 		large: '/assets/backgrounds/IntelligentCommandCenters.jpg',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	h1: 'SMART FACTORY',
	// 	h2: 'Solutions',
	// 	copy: 'Visualize digital replicas of physical manufacturing assets along with real-time data from IoT devices, sensors, and machines, and receive AI-powered notifications when issues arise.',
	// 	backgrounds: {
	// 		small: '/assets/backgrounds/SmartManufacturing_sm.jpg',
	// 		large: '/assets/backgrounds/SmartManufacturing.jpg',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	h1: 'IMMERSIVE',
	// 	h2: 'BROADCAST EXPERIENCES',
	// 	copy: 'Turn any live broadcast into a memorable experience by extending the viewing beyond TV to smartphones, VR/AR, laptops, and video walls.',
	// 	backgrounds: {
	// 		small: '/assets/backgrounds/EpicExperiences_sm.jpg',
	// 		large: '/assets/backgrounds/EpicExperiences.jpg',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	h1: 'CREATIVE',
	// 	h2: 'SERVICES',
	// 	copy: 'Award-winning creative services from the team who delivered <span>the highest profile broadcast design productions</span> worldwide including the Super Bowl, the Olympics, and US Presidential Elections.',
	// 	backgrounds: {
	// 		small: '/assets/backgrounds/home.jpg',
	// 		large: '/assets/backgrounds/home.jpg',
	// 	},
	// 	cta: {
	// 		label: 'Learn More',
	// 		url: '/creative-services',
	// 	},
	// 	duration: 10,
	// },
	// {
	// 	h1: 'Interactive',
	// 	h2: 'Video Walls',
	// 	copy: 'Create stunning interactive <span>visual experiences for any video wall</span> configuration.',
	// 	backgrounds: {
	// 		small: '/assets/backgrounds/InteractiveWalls.jpg',
	// 		large: '/assets/backgrounds/InteractiveWalls.jpg',
	// 	},
	// 	duration: 10,

	/* {
		h1: 'Virtual New Year',
		h2: 'Live from Times Square in New York City!',
		copy: 'Zenapptic AI is proud to work with our partners FLY Studio and Behaviour Interactive to power the Times Square New Year\'s Eve Metaverse',
		backgrounds: {
			small: '/assets/backgrounds/VNYE-banner-sm.jpg',
			large: '/assets/backgrounds/VNYE-banner.jpg',
		},
		cta: {
			label: 'Read More',
			url: 'https://financialpost.com/pmn/press-releases-pmn/business-wire-news-releases-pmn/ring-in-2022-in-the-virtual-world-of-times-square',
			newWindow: true,
		},
		duration: 10,
	},*/
];