<script setup lang="ts"></script>

<template>
  <div class="why-choose-zen">
    <h2>Why <b>choose</b> the <b>Zen3</b> platform</h2>

    <div class="container container-lg">
      <p>
        Simplify the creation and management of interactive experiences with our
        adaptable and scalable platform
      </p>

      <div class="zen-platform-items">
        <div class="zen-platform-icon-header">
          <img
            src="/assets/icons/ai-powered-gear-icon.png"
            alt="ai powered icon"
          />
          AI-POWERED RULES ENGINE | GENERATIVE AI
        </div>

        <div class="icons">
          <IconBlock>
            <li>
              <div class="icon">
                <img
                  src="/assets/icons/security-icon.svg"
                  alt="security icon"
                />
              </div>
              <h3>SECURITY &amp;<br />COMMUNITY MANAGEMENT</h3>
            </li>
            <li>
              <div class="icon">
                <img
                  src="/assets/icons/content-icon.svg"
                  alt="content management icon"
                />
              </div>
              <h3>CONTENT<br />MANAGEMENT</h3>
            </li>
            <li>
              <div class="icon">
                <span class="material-icons" style="color: black"> hub </span>
              </div>
              <h3>INTEGRATION WITH<br />LIVE DATA &amp; EXTERNAL SYSTEMS</h3>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/icons/cloud-icon.svg" alt="cloud icon" />
              </div>
              <h3>CLOUD &amp; ON-PREMISE RENDERING</h3>
            </li>
          </IconBlock>

          <IconBlock>
            <li>
              <div class="icon">
                <img src="/assets/icons/virtual-icon.svg" alt="virtual icon" />
              </div>
              <h3>VISUALIZATION &amp; INTERACTIVITY</h3>
            </li>
            <li>
              <div class="icon">
                <img
                  src="/assets/icons/distributed-icon.svg"
                  alt="distributed icon"
                />
              </div>
              <h3>DISTRIBUTED<br />CONTROL</h3>
            </li>
            <li>
              <div class="icon">
                <img
                  src="/assets/icons/analytics-icon.svg"
                  alt="analytics icon"
                />
              </div>
              <h3>MONITORING &amp; ANALYTICS</h3>
            </li>
            <li>
              <div class="icon">
                <span class="material-icons" style="color: black">
                  notifications
                </span>
              </div>
              <h3>REAL-TIME ALERTS &amp; NOTIFICATIONS</h3>
            </li>
          </IconBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.why-choose-zen {
  align-items: center;
  margin: auto;
  padding: 5rem 2rem 10rem 2rem;
  color: white;

  h2 {
    font-size: 5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 200;
    margin-bottom: 0;
    margin-top: 1.6rem;
  }

  .container {
    max-width: 1366px;
    margin: auto;

    p {
      font-size: 1.7rem;
      text-align: center;
      margin-top: -0.8rem;
      letter-spacing: 0.03rem;
    }

    .zen-platform-items {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      color: white;

      .zen-platform-icon-header {
        color: white;
        font-size: 3rem;
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: center;
        font-weight: bold;
        border-radius: 6px;
        margin: auto;
        transform: translateY(-50%);
        position: relative;
        bottom: -5.3rem;
        background-color: #1a1719;
        padding: 0 2rem;

        @media (max-width: 767px) {
          text-align: center;
        }
      }

      .icons {
        padding: 3rem;
        width: 80%;
        margin: auto;
        padding-top: 9rem;
        border: 1px dashed white;
      }
    }

    .body-action {
      text-align: center;
      padding-top: 3rem;
    }
  }

  @media (max-width: 1100px) {
    .container {
      .zen-platform-items {
        .icons {
          width: 95%;
        }
      }
    }
  }

  @media (max-width: 920px) {
    .container {
      .zen-platform-items {
        .zen-platform-icon-header {
          font-size: 1.7rem;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .container {
      .zen-platform-items {
        .zen-platform-icon-header {
          bottom: -1rem;
          margin-top: 2rem;
          background-color: transparent;
        }
      }
    }
  }
}

.video-player-override {
  .video-placeholder {
    img {
      display: none;
    }
  }

  .play {
    display: none;
  }
}
</style>
