<template>
  <ul class="icon-block">
    <slot />
  </ul>
</template>

<style lang="scss">
ul.icon-block {
  list-style-type: none;
  padding: 0;
  margin: 0 0 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4rem;
  width: 100%;

  li {
    flex: 1;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .icon {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;
      }

      span {
        color: rgb(var(--color-dark));
        font-size: 6rem;
      }
    }

    h3,
    p {
      text-align: center;
      max-width: 13.6rem;
      font-weight: 500;
      font-size: 1.4rem;
    }
  }
}
</style>
