<script setup lang="ts">
const scrollingToTarget = useScrollingToTarget();
let sections: NodeListOf<HTMLElement>;
let hashObserver: IntersectionObserver;
const { sendScreenView } = useAnalytics();

onMounted(() => {
  hashObserver = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      if (!scrollingToTarget.value) {
        if (entries[0].isIntersecting) {
          const id = entries[0].target.id;
          sendScreenView({
            action: "ScrollView",
            category: id,
          });
        }
      }
    },
    { rootMargin: "-20% 0px -20% 0px" }
  );

  sections = document.querySelectorAll("section");
  sections.forEach((section) => {
    hashObserver.observe(section);
  });
});
</script>

<template>
  <main class="container home">
    <section id="feature" class="observable">
      <div class="anchor" />
      <feature />
    </section>

    <section id="aboutUs" class="observable">
      <div class="anchor" />
      <about />
    </section>

    <section id="solutions" class="observable">
      <div class="anchor" />
      <SolutionsFeature />
    </section>

    <section id="platform" class="observable">
      <div class="anchor" />
      <ZenExperienceVideo />
    </section>

    <section id="whychoose" class="observable">
      <div class="anchor" />
      <platform />
    </section>

    <section id="partners" class="observable">
      <div class="anchor" />
      <Partners />
    </section>

    <section id="contact" class="observable">
      <div class="anchor" />
      <Contact />
    </section>
  </main>
</template>
